<template>

<span>
    <v-layout mb-4>
      <v-flex xs10 ml-3>
        <h2 class="lime--text lighten-1 pt-3">Gerenciar Banner - Edição</h2>
      </v-flex>
      <v-flex xs2 mr-3 text-right>
        <v-btn
          color="blue-grey"
          class="black--text"
          :to="{name: 'banners'}"
        >
          <v-icon dark>keyboard_arrow_left</v-icon>
          Voltar
        </v-btn>
      </v-flex>
    </v-layout>
    
    <form-banner :banner="banner" :update="true"></form-banner>
</span>
        
</template>

<script>
import FormBannerComponent from "./partial/FormBannerComponent";

export default {
  name: "EditBannerComponent",
  created() {
    this.loadBanner()
  },
  props: {
    bnnr_id: {
      require: true
    }
  },
  data () {
    return {
      banner: {
        bnnr_id: '',
        bnnr_nome: '',
        bnnr_status: false,
      },
    }
  },
  methods: {
    loadBanner() {
      this.$store.dispatch('loadBanner', this.bnnr_id)
        .then(response => this.banner = response)
        .catch(error => {
          this.$swal({
            position: "center",
            icon: "error",
            title: 'Erro',
            text: 'Dados não localizado',
            showConfirmButton: true,
            timer: 6000
          });
        })
    },
  },
  components: {
    formBanner: FormBannerComponent
  }
};
</script>

<style scoped>

</style>
